var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("PGA Governance")]),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { tile: "" } },
        [
          _c("v-card-title", [_vm._v(" PGA for Company Governance ")]),
          _c(
            "v-card-text",
            [
              _c("h3", [_vm._v(" Open source Technologies used ")]),
              _c(
                "v-list",
                _vm._l(_vm.listTechnologies, function (technology, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(technology.icon) },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(technology.text) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }